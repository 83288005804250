/* eslint-disable complexity */
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Dropdown, { Option } from 'src/components/dropdown/dropdown';
import EmptyState from 'src/components/empty-state/empty-state';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import {
  getFormatOptions,
  getListOptions,
  getRunDates,
  RanklistBaseInfo,
  RanklistResult,
} from 'src/components/player-ranking/helpers';
import Spinner from 'src/components/spinner/spinner';
import { getCategoryOptions } from 'src/components/usta-ranking-filters/usta-ranking-filters';
import { isFormatCombined } from 'src/components/usta-rankings/helpers';
import { GET_RANK_LIST } from 'src/components/usta-rankings/usta-ranking-queries';
import { GetPlayersEventPointsForList } from 'src/graphql-types/GetPlayersEventPointsForList';
import { GetRankingsEvents as GetEvents } from 'src/graphql-types/GetRankingsEvents';
import { ListTypeEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { Ranklist } from 'src/graphql-types/Ranklist';
import { FormatOptionsEnum } from 'src/utils/helper/rankings';
import { StringParam, useQueryParams } from 'use-query-params';

import { GET_EVENTS, GET_PLAYERS_EVENT_POINTS_FOR_LIST } from './player-events-queries';
import { GET_PLAYER_RANK_LISTS } from './player-ranking-queries';
import * as styles from './player-ranking.module.less';

export interface Filters {
  category: PlayerTypeEnum | '';
  listType: ListTypeEnum | '';
  format: FormatOptionsEnum | '';
  list: ListTypeEnum | '';
  date?: string;
}

const initialFiltersState: Filters = {
  category: '',
  listType: '',
  format: '',
  list: '',
  date: undefined,
};

interface GetEventsProps {
  events?: GetEvents;
  eventsPlayersData?: GetPlayersEventPointsForList;
  ranklist: Ranklist['ranklist'];
}

function getEvents({ events, eventsPlayersData, ranklist }: GetEventsProps) {
  const eventPlayers = eventsPlayersData?.playersEventPointsForList?.map((e: any) => ({
    ...e,
    eventPlayers: e.eventProperties?.eventPlayers,
    tournament: e.eventProperties?.tournament,
    level: e.eventProperties?.level,
    description: e.eventProperties?.description,
    isVerified: true,
  }));

  // Remove invalid events, eg `null`
  const validEvents = events?.events?.filter(Boolean) || [];
  const validEventPlayers = eventPlayers?.filter(Boolean) || [];

  const allEvents = [...validEvents, ...validEventPlayers]; // Combine events
  const uniqueEvents = [...new Map(allEvents.map((item) => [`${item.eventId}-${item.collectionId}`, item])).values()]; // Remove duplicates
  // Remove events whitch end date isn't in between ranklist dateRange
  return uniqueEvents?.filter(
    (e) =>
      e?.tournament?.tournamentEnd > ranklist?.dateRange?.start ||
      e?.tournament?.tournamentEnd < ranklist?.dateRange?.end,
  );
}

function getListTypeOptions(category: PlayerTypeEnum, t: TFunction) {
  if (category === PlayerTypeEnum.JUNIOR) {
    return [
      { value: '', label: t('any list type') },
      {
        value: ListTypeEnum.STANDING,
        label: t('standing'),
      },
      {
        value: ListTypeEnum.SEEDING,
        label: t('seeding'),
      },
      {
        value: ListTypeEnum.BONUS_POINTS,
        label: t('bonus'),
      },
      {
        value: ListTypeEnum.YEAR_END,
        label: t('year end'),
      },
      {
        value: ListTypeEnum.QUOTA,
        label: t('quota'),
      },
    ];
  }

  return [
    { value: '', label: t('any list type') },
    {
      value: ListTypeEnum.STANDING,
      label: t('standing'),
    },
    {
      value: ListTypeEnum.YEAR_END,
      label: t('year end'),
    },
  ];
}

interface PlayerRankingsProps {
  id: string;
}

const useFiltersReset = (filters: Filters, setFilters: Dispatch<SetStateAction<Filters>>): void => {
  useEffect(() => {
    setFilters({ ...filters, listType: '' });
  }, [filters.category]);

  useEffect(() => {
    setFilters({ ...filters, format: '' });
  }, [filters.listType]);

  useEffect(() => {
    setFilters({ ...filters, list: '' });
  }, [filters.format]);

  useEffect(() => {
    setFilters({ ...filters, date: '' });
  }, [filters.list]);
};

export const PlayerRankings: React.FC<PlayerRankingsProps> = ({ id: uaid }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<Filters>(initialFiltersState);
  const [date, setDate] = useState<Option | null>(null);
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  const isAllFiltersSelected = filters.category && filters.format && filters.list && filters.date;

  const [query, setQuery] = useQueryParams({
    ranklistId: StringParam,
    tab: StringParam,
  });

  const getRanklistFilters = () => {
    const { category, listType, format } = filters;
    let ranklistFilters = {};

    if (category) {
      ranklistFilters = { ...ranklistFilters, playerType: category };
    }

    if (listType) {
      ranklistFilters = { ...ranklistFilters, listType };
    }

    if (format) {
      if (format === FormatOptionsEnum.COMBINED) {
        ranklistFilters = { ...ranklistFilters };
      } else {
        ranklistFilters = { ...ranklistFilters, matchFormat: format };
      }
    }

    return { ...ranklistFilters };
  };

  // Resets linked filters
  useFiltersReset(filters, setFilters);

  useEffect(() => {
    if (!filters.date) {
      setDate(null);
    }
  }, [filters.date]);

  useEffect(() => {
    if (filters.category && filters.format && filters.list && filters.date) {
      date && handleSelect(date, 'date');
    }
  }, [date]);

  const { data: d } = useQuery<Ranklist>(GET_RANK_LIST, {
    client: meshGatewayClient,
    variables: { id: query.ranklistId, itemPageArgs: { limit: 1, skip: 0 } },
    skip: !query.ranklistId,
  });

  const { data, loading: loadingRanklists } = useQuery(GET_PLAYER_RANK_LISTS, {
    client: meshGatewayClient,
    variables: {
      ranklistFilter: { ...getRanklistFilters() },
      pageArgs: { limit: 1000 },
      itemPageArgs: { skip: 0, limit: 1000 },
      itemFilter: { participantIds: [uaid] },
    },
    skip: !filters.category,
  });

  const { data: ranklistsByCategoryData, loading: loadingRanklistCategory } = useQuery(GET_PLAYER_RANK_LISTS, {
    client: meshGatewayClient,
    variables: {
      ranklistFilter: { playerType: filters.category },
      pageArgs: { limit: 1000 },
      itemFilter: { participantIds: [uaid] },
      itemPageArgs: { skip: 0, limit: 1000 },
    },
    skip: !filters.category,
  });

  const categoryRanklists = ranklistsByCategoryData?.ranklists?.filter(
    (r) => r?.rankingItemsPaginated?.items?.length > 0,
  );

  const validRanklists = data?.ranklists?.filter((r) => r?.rankingItemsPaginated?.items?.length > 0);
  const ranklists = filterCombinedLists(
    validRanklists,
    filters.category as PlayerTypeEnum,
    filters.format as FormatOptionsEnum,
  );

  const ranklist = ranklists?.find((r) => r.createdAt === filters.date);

  const { data: eventsData, loading: loadingEvents } = useQuery<GetEvents>(GET_EVENTS, {
    client: meshGatewayClient,
    variables: {
      filter: {
        playerId: uaid,
        playerType: ranklist?.playerType,
        matchFormat: ranklist?.matchFormat,
        playerLevel: ranklist?.playerLevel,
        gender: ranklist?.gender,
        ...(ranklist?.familyCategory ? { familyCategory: ranklist?.familyCategory } : {}),
        ...(ranklist?.playerType === PlayerTypeEnum.JUNIOR ? {} : { ageRestriction: ranklist?.ageRestriction }),
      },
    },
    skip: !ranklist,
  });

  const { data: eventsPlayersData } = useQuery<GetPlayersEventPointsForList>(GET_PLAYERS_EVENT_POINTS_FOR_LIST, {
    client: meshGatewayClient,
    variables: {
      listId: ranklist?.id,
      playerId: uaid,
    },
    skip: !data || !ranklist?.id,
  });

  const events = getEvents({
    events: eventsData,
    eventsPlayersData: eventsPlayersData,
    ranklist,
  });

  const reducedListOptions = getListOptions(ranklists, t, query?.ranklistId as string);
  const applicableRanklists = getApplicableRanklists(ranklists, filters);
  const runDateOptions = getRunDates(applicableRanklists, t);

  // Handles redirects from rankings
  useEffect(() => {
    if (query.ranklistId) {
      const { id, playerType, matchFormat, createdAt = new Date(), listType } = d?.ranklist ?? {};
      const runDateOption = runDateOptions.find((r) => r.value === new Date(createdAt)?.toISOString());

      const getFormat = () => {
        const isCombined = isFormatCombined({ listType, playerType, matchFormat });
        if (isCombined) return FormatOptionsEnum.COMBINED;
        return matchFormat;
      };

      setFilters({
        ...filters,
        category: playerType,
        listType,
        format: getFormat(),
        list: id,
      } as any);

      if (runDateOption && !filters.date) {
        handleSelect(runDateOption, 'date');
      }
    }
  }, [d]);

  useEffect(() => {
    if (query.ranklistId) {
      const { createdAt = new Date() } = d?.ranklist ?? {};
      const runDateOption = runDateOptions.find((r) => r.value === new Date(createdAt)?.toISOString());
      if (runDateOption && !filters.date) {
        setFilters({ ...filters, date: runDateOption.value });
      }
    }
  }, [d, ranklists]);

  const handleSelect = (option: Option, field: keyof Filters): void => {
    if (query.ranklistId && query.ranklistId !== 'none') {
      setQuery({ ranklistId: null });
    }
    setFilters({ ...filters, [field]: option.value });
  };

  // Handle expanded initial state
  useEffect(() => {
    if (eventsData && eventsPlayersData) {
      const events = getEvents({
        events: eventsData,
        eventsPlayersData: eventsPlayersData,
        ranklist,
      });
      const expandedUniqueIds = events
        ?.map((event) => ({ [event.id]: false }))
        ?.reduce((prev, curr) => ({ ...prev, ...curr }), {});
      setExpanded(expandedUniqueIds);
    }
  }, [eventsData, eventsPlayersData, ranklist]);

  const isExpanded = (id: string): boolean => expanded?.[id] === true;
  const isAllExpanded = Object.values(expanded).every((value) => value);

  const handleChange = (id: string) => (_, isExpanded: boolean) => {
    setExpanded({ ...expanded, [id]: isExpanded });
  };

  const handleBatchExpand = (newState: boolean) => {
    const updatedValues = Object.keys(expanded)
      .map((key) => ({ [key]: newState }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {});
    setExpanded(updatedValues);
  };

  const ranklistBaseInfo = useMemo(() => {
    const [rankingItem] = ranklist?.rankingItemsPaginated?.items ?? [];
    const rank = rankingItem?.rank;
    const {
      singles: singlePoints,
      doubles: doublePoints,
      bonus: bonusPoints,
      total: totalPoints,
    } = rankingItem?.points ?? {};

    if (!rank || !totalPoints || !isAllFiltersSelected) {
      return undefined;
    }

    return {
      rank,
      singlePoints,
      doublePoints,
      bonusPoints,
      totalPoints,
      isCombined: filters.format === FormatOptionsEnum.COMBINED,
      isAdult: filters.category === PlayerTypeEnum.ADULT,
      format: filters.format,
    };
  }, [isAllFiltersSelected, filters.category, filters.format]);

  return (
    <Panel title={t('rankings')}>
      <CustomGrid container spacing={{ margins: { sm: 'top' } }}>
        <Dropdown
          placeholder={t('select player category')}
          selected={filters.category}
          onSelect={(o) => handleSelect(o, 'category')}
          spacing={{ margins: { xs: 'right' } }}
          options={getCategoryOptions(t, true)}
          disabled={loadingRanklists}
        />
        <Dropdown
          placeholder={t('select list type')}
          selected={filters.listType}
          onSelect={(o) => handleSelect(o, 'listType')}
          spacing={{ margins: { xs: 'right' } }}
          options={getListTypeOptions(filters.category as PlayerTypeEnum, t)}
          disabled={!filters.category || loadingRanklists || categoryRanklists?.length === 0 || loadingRanklistCategory}
        />
        <Dropdown
          placeholder={t('select format')}
          selected={filters.format}
          onSelect={(o) => handleSelect(o, 'format')}
          spacing={{ margins: { xs: 'right' } }}
          options={getFormatOptions(filters.category, filters.listType, t)}
          disabled={!filters.listType || loadingRanklists}
        />
        <Dropdown
          placeholder={t('select ranking list')}
          selected={filters.list}
          onSelect={(o) => handleSelect(o, 'list')}
          spacing={{ margins: { xs: 'right' } }}
          options={reducedListOptions ?? []}
          disabled={!filters.format || ranklists?.length === 0 || loadingRanklists}
        />
        <Dropdown
          placeholder={t('select run date')}
          selected={filters.date || date?.value}
          onSelect={(o) => setDate(o)}
          spacing={{ margins: { xs: 'right' } }}
          options={runDateOptions}
          disabled={!filters.list || ranklists?.length === 0 || loadingRanklists}
        />
        <Button
          type="submit"
          onClick={() => date && handleSelect(date, 'date')}
          spacing={{ margins: { sm: 'left' } }}
          disabled={!isAllFiltersSelected && !date}
        >
          {t('update')}
        </Button>
      </CustomGrid>
      {(loadingRanklists || loadingRanklistCategory) && (
        <CustomGrid
          container
          justifyContent="center"
          alignItems="center"
          spacing={{ margins: { lg: ['top', 'bottom'] } }}
        >
          <Spinner />
        </CustomGrid>
      )}
      {!loadingRanklists && !loadingRanklistCategory && (
        <>
          {(!ranklistBaseInfo || !isAllFiltersSelected) && (
            <Grid container justifyContent="center">
              <EmptyState title={t('no rankings yet')} icon="xl-rankings" subtitle={t('rankings empty subtitle')} />
            </Grid>
          )}
          {ranklistBaseInfo && isAllFiltersSelected && (
            <>
              <RanklistBaseInfo {...ranklistBaseInfo} />
              {loadingEvents && (
                <CustomGrid spacing={{ margins: { lg: ['top', 'bottom'] } }}>
                  <Spinner />
                </CustomGrid>
              )}
              <CustomGrid
                container
                justifyContent="flex-end"
                hide={events?.length === 0 || loadingEvents}
                spacing={{ margins: { lg: 'top', xxs: 'bottom' } }}
              >
                <Button linkStyle onClick={() => handleBatchExpand(!isAllExpanded)}>
                  {isAllExpanded ? t('collapse all') : t('expand all')}
                  <Icon name={isAllExpanded ? 'sm-up' : 'sm-down'} className={styles.arrowIcon} />
                </Button>
              </CustomGrid>
              {!loadingEvents &&
                events?.map((event, index) => (
                  <RanklistResult
                    key={`${event.id}-${index}`}
                    expanded={isExpanded(event.id)}
                    onChange={handleChange(event.id)}
                    ranklist={ranklist}
                    data={event}
                    uaid={uaid}
                    spacing={index !== 0 ? { margins: { lg: 'top' } } : {}}
                  />
                ))}
            </>
          )}
        </>
      )}
    </Panel>
  );
};

function getApplicableRanklists(ranklists: Ranklist['ranklist'][], filters: Filters) {
  return ranklists?.filter((ranklist) => {
    const selectedRanklist = ranklists.find((ranklist) => ranklist.id === filters.list);

    if (!selectedRanklist) {
      return false;
    }

    return (
      ranklist.ageRestriction === selectedRanklist.ageRestriction &&
      ranklist.matchFormat === selectedRanklist.matchFormat &&
      ranklist.matchFormatType === selectedRanklist.matchFormatType &&
      ranklist.listType === selectedRanklist.listType &&
      ranklist.playerType === selectedRanklist.playerType &&
      ranklist.gender === selectedRanklist.gender &&
      ranklist.genderModifier === selectedRanklist.genderModifier &&
      ranklist.familyCategory === selectedRanklist.familyCategory &&
      ranklist.region === selectedRanklist.region
    );
  });
}

// When combined is selected, it's not possible to filter listType for both bonus points and standing lists
// Omit listType filter and filter locally
function filterCombinedLists(ranklists: any[], category: PlayerTypeEnum, format: FormatOptionsEnum) {
  if (category === PlayerTypeEnum.JUNIOR && format === FormatOptionsEnum.COMBINED) {
    return ranklists?.filter(
      (r) =>
        r.listType === ListTypeEnum.STANDING ||
        r.listType === ListTypeEnum.BONUS_POINTS ||
        r.listType === ListTypeEnum.YEAR_END ||
        r.listType === ListTypeEnum.QUOTA,
    );
  }

  return ranklists;
}
